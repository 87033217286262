// import Timer from '@/utils/core/util/Timer'
import * as _ from 'lodash'
import store from '@/store'
import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'
import ApiController, { LadiesResponsePromise } from '@/api/ApiController'
import { searchStore } from '@/store/search/search'
import { LadiesResponse, LadyItemData, SearchParameter } from '@/types/type'

@Module({ dynamic: true, store, name: 'ladies', namespaced: true })
class LadiesModule extends VuexModule {
  // 表示用のリストデータ
  public ladies: LadyItemData[] | null = null

  // 現在実行中の getLadies
  public runningLadiesResponsePromise: LadiesResponsePromise | null = null

  // 検索結果件数
  public totalCount = 0

  // 前回の検索実行時の条件
  public lastSearchParameter: SearchParameter | null = null

  // 前回の検索実行時のskip値
  public lastSkip: number | null = null

  /**
   * 表示用のリストデータを返します
   */
  public get getLadies() {
    return this.ladies
  }

  /**
   * 表示用のリストデータを更新
   * @param ladies 女性情報リスト
   */
  @Mutation
  public setLadies(ladies: LadyItemData[] | null) {
    this.ladies = ladies
  }

  /**
   * 検索結果件数を更新
   * @param count 検索結果件数
   */
  @Mutation
  public setTotalCount(count: number) {
    this.totalCount = count
  }

  /**
   * 実行中のAPI処理Promise
   * @param promise
   */
  @Mutation
  public setRunningLadiesPromise(promise: LadiesResponsePromise | null) {
    this.runningLadiesResponsePromise = promise
  }

  /**
   * 前回の検索実行時の条件として保持
   * @param searchParameter
   */
  @Mutation
  public setLastSearchParameter(searchParameter: SearchParameter | null) {
    this.lastSearchParameter = searchParameter
  }

  /**
   * 前回の検索実行時のskip値として保持
   * @param skip
   */
  @Mutation
  public setLastSkip(skip: number | null) {
    this.lastSkip = skip
  }

  /**
   * リストデータを消去
   */
  @Mutation
  public clearLadies() {
    this.ladies = null
  }

  /**
   * Ladiesデータを取得します
   */
  @Action
  public async loadLadies() {
    // 同一条件の場合は読み込みをスキップ
    if (_.isEqual(this.lastSearchParameter, searchStore.getSearchParameter) && this.lastSkip == searchStore.getSkip) {
      return
    }

    // リストを削除
    this.clearLadies()

    // API実行中の場合キャンセル
    if (this.runningLadiesResponsePromise) {
      this.runningLadiesResponsePromise.cancel()
      this.setRunningLadiesPromise(null)
    }

    const runningLadiesResponsePromise = new ApiController().getLadies(searchStore.getSearchParameter, searchStore.getSkip)

    // キャンセルできるようにPrmiseを保持
    this.setRunningLadiesPromise(runningLadiesResponsePromise)

    // 検索条件を保持
    this.setLastSearchParameter(Object.assign({}, searchStore.getSearchParameter))
    this.setLastSkip(searchStore.getSkip)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ladiesResponse: LadiesResponse = await runningLadiesResponsePromise.catch(error => {
      // エラー時 - ApiControllerの方で処理
      return {
        totalCount: 0,
        ladies: [],
      }
    })

    this.updateTotalCount(ladiesResponse.totalCount)
    this.setLadies(ladiesResponse.ladies)
  }

  /**
   * トータル件数を更新
   */
  @Action
  public updateTotalCount(value: number) {
    this.setTotalCount(value)

    // 最大ページ番号も更新
    const pageMaxNo = Math.ceil(value / searchStore.getLimit)
    searchStore.setPageMaxNo(pageMaxNo)
  }
}

export const ladiesStore = getModule(LadiesModule)
