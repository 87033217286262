import Vue from 'vue'
import VueGtag from 'vue-gtag'
import VueCarousel from 'vue-carousel'
import VueCompositionAPI from '@vue/composition-api'
import ApiController from '@/api/ApiController'
import ApiPath from '@/api/ApiPath'
import App from './App.vue'
import router from '@/router'
import store from './store'
import CommonMaterials from '@/plugins/materials'

// store
import { sampleStore } from '@/store/sample/sample'

require('@/assets/sass/reset.scss')
require('@/assets/sass/styles.scss')

Vue.config.productionTip = false
Vue.use(VueCompositionAPI)
Vue.use(VueCarousel)
Vue.use(CommonMaterials)

// analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS == 'YES') {
  Vue.use(VueGtag, {
    config: { id: 'G-RWEJPYKCW7' },
  })
}

// サンプルかどうか
let isSample = false

// Vueの開始
function initializeVue() {
  // サンプルかどうか状態を保持
  sampleStore.setIsSample(isSample)

  new Vue({
    router,
    store,
    render: h => {
      return h(App)
    },
  }).$mount('#app')
  sampleStore.setIsSample(isSample)
}

;(async () => {
  // API基本パスを指定
  ApiPath.apiBasePath = process.env.VUE_APP_API_END_POINT

  // サンプル用ページかどうか
  if (location.pathname.indexOf('/sample') == 0) {
    isSample = true
    ApiPath.apiType = 'sample'
  }

  // MEを呼ぶ
  new ApiController()
    .getMe()
    .then(() => {
      // Vueを開始
      initializeVue()
    })
    .catch(() => {
      // 未ログイン時：Websiteでエラーを表示
      if (isSample) {
        location.replace(process.env.VUE_APP_WEBSITE + '/?sample_failed=2')
      } else {
        location.replace(process.env.VUE_APP_WEBSITE + '/?login_failed=2')
      }
    })
})()
