



















import { defineComponent, reactive, onMounted, onUpdated, onUnmounted } from '@vue/composition-api'

export default defineComponent({
  components: {},

  props: {
    errorName: {
      type: String,
    },
    errorTitle: {
      type: String,
    },
    errorText: {
      type: String,
    },
  },

  setup() {
    const state = reactive({})

    onMounted(() => {
      // do nothing.
    })

    onUpdated(() => {
      // do nothing.
    })

    onUnmounted(() => {
      // do nothing.
    })

    return {
      state,
    }
  },
})
