


































































import Vue from 'vue'
import { defineComponent, reactive, computed, onMounted, onUpdated, onUnmounted, toRefs, watch } from '@vue/composition-api'
import { NavigationGuard, NavigationGuardNext } from 'vue-router'
import { onBeforeRouteEnter, onBeforeRouteUpdate } from '@/plugins/router'
import JSScrollController from '@/utils/core/controller/JSScrollController'

import ApiPath, { LOGIN } from '@/api/ApiPath'

// const
import { orderRecommended } from '@/const/search'

// store
import { sampleStore } from '@/store/sample/sample'
import { ladiesStore } from '@/store/ladies/ladies'
import { searchStore } from '@/store/search/search'

// vue
import SwitchButton from '@/plugins/materials/atoms/SwitchButton.vue'
import Pagination from '@/plugins/materials/atoms/Pagination.vue'
import ContactButton from '@/components/organisms/ContactButton.vue'
import Lady from '@/components/organisms/Lady.vue'
import { Route } from 'vue-router'

// ブラウザ back, forward をハンドリング
let popStateDetected = false
window.addEventListener('popstate', () => {
  popStateDetected = true
})

export default defineComponent({
  components: {
    ContactButton,
    SwitchButton,
    Pagination,
    Lady,
  },
  setup(prop, context) {
    const state = reactive({
      // 検索オーダー種類
      listTypeIndex: computed(() => {
        return searchStore.getOrder == orderRecommended ? 1 : 0
      }),

      // 女性リスト
      ladies: computed(() => {
        return ladiesStore.getLadies
      }),
    })
    const refState = toRefs(state)

    // ログイン用URL
    const loginUrl = ApiPath.getPath(LOGIN)

    // プラウザのback, forwardが行われた場合は、queryを使ってリストを読み込むように
    const f: NavigationGuard<Vue> = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      Vue.nextTick(() => {
        next()
        if (popStateDetected && ((to && to.name?.indexOf('Home') != -1) || (to && to.name?.indexOf('Home/Page') != -1))) {
          // 検索条件をqueryから反映
          searchStore.updateSearchParameterFromQuery(context.root.$route.query as Record<string, string>)
          ladiesStore.loadLadies()
        }
        popStateDetected = false
      })
    }
    onBeforeRouteEnter(f)
    onBeforeRouteUpdate(f)

    // ladiesが変化を監視
    watch([refState.ladies], () => {
      // ページを上部までスクロールする処理
      new JSScrollController().scrollToTop(0)
    })

    onMounted(() => {
      // 検索条件をqueryから反映
      searchStore.updateSearchParameterFromQuery(context.root.$route.query as Record<string, string>)
      // 女性リストを読み込み
      ladiesStore.loadLadies()
    })

    onUpdated(() => {
      // do nothing.
    })

    onUnmounted(() => {
      // do nothing.
    })

    // リストタイプの更新
    const updateListType = (index: number) => {
      if (index == 0) {
        searchStore.updateOrder(undefined)
      } else if (index == 1) {
        searchStore.updateOrder(orderRecommended)
      }

      const searchParam = Object.assign({}, searchStore.getSearchParameter) as Record<string, string>
      if (searchParam) context.root.$router.push({ path: '/', query: searchParam })

      // 女性リストを読み込み
      ladiesStore.loadLadies()
    }

    // Paginationのクリックハンドラ
    const clickPage = pageNo => {
      // ページ番号を反映
      if (pageNo == 1) context.root.$router.push({ path: '/', query: context.root.$route.query })
      else context.root.$router.push({ path: '/page/' + pageNo, query: context.root.$route.query })

      // 女性リストを読み込み
      ladiesStore.loadLadies()
    }

    return {
      ...refState,
      sampleStore,
      searchStore,
      loginUrl,
      updateListType,
      clickPage,
    }
  },
})
