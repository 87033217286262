










import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {},

  components: {},
  setup(prop, context) {
    // 閉じる
    const close = () => {
      context.emit('clickClose')
    }
    return {
      close,
    }
  },
})
