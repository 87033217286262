








import { defineComponent, reactive, toRefs /*, computed*/ } from '@vue/composition-api'

export default defineComponent({
  model: {
    prop: 'value',
    event: 'change-value',
  },

  props: {
    // 選択されているボタンIndex
    value: {
      type: Number,
      default: 0,
    },
  },

  setup(props, context) {
    const state = reactive({})

    // クリック
    const click = (index: number) => {
      if (props.value != index) context.emit('change-value', index)
    }

    return {
      ...toRefs(state),
      click,
    }
  },
})
