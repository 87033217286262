// import Timer from '@/utils/core/util/Timer'
import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'
import Error from '@/components/pages/Error.vue'
// import NotFound from '@/components/pages/NotFound.vue'
import Home from '@/components/pages/Home.vue'
import Detail from '@/components/pages/Detail.vue'
import Search from '@/components/pages/Search.vue'

// store
import { sampleStore } from '@/store/sample/sample'
import { searchStore } from '@/store/search/search'
import { ladyStore } from '@/store/lady/lady'

Vue.use(VueRouter)

// 共通タイトル
const commonTitle = '完全会員制プライベートサロン ヴァンキャトル'

const routes: Array<RouteConfig> = [
  //
  // Member
  //
  // Home: トップページ
  {
    name: 'Home',
    path: '/',
    component: Home,
    meta: { title: `MEMBER | ${commonTitle}` },
    children: [
      // 詳細画面（モーダル）
      {
        name: 'Modal/Detail',
        path: 'detail/:id',
        component: Detail,
      },
      // 検索フォーム（モーダル）
      {
        name: 'Modal/Search',
        path: 'search',
        component: Search,
        meta: { title: `検索 | ${commonTitle}` },
      },
    ],
  },
  // Home: ページング
  {
    name: 'Home/Page',
    path: '/page/:pageno',
    component: Home,
    meta: { title: `MEMBER | ${commonTitle}` },
  },
  //
  // Sample
  //
  // Sample Home: トップページ
  {
    name: 'Sample/Home',
    path: '/sample/',
    component: Home,
    meta: { title: `SAMPLE | ${commonTitle}` },
    children: [
      // 詳細画面（モーダル）
      {
        name: 'Sample/Modal/Detail',
        path: 'detail/:id',
        component: Detail,
      },
      // 検索フォーム（モーダル）
      {
        name: 'Sample/Modal/Search',
        path: 'search',
        component: Search,
        meta: { title: `検索 (SAMPLE) | ${commonTitle}` },
      },
    ],
  },
  // Sample Home: ページング
  {
    path: '/sample/page/:pageno',
    name: 'Sample/Home/Page',
    component: Home,
    meta: { title: `SAMPLE | ${commonTitle}` },
  },
  // 404: File not found.
  {
    path: '*',
    name: 'NotFound',
    component: Error,
    props: { errorId: '404' },
    meta: { title: `404 Not Found | ${commonTitle}` },
  },
  // Error: エラー画面
  {
    path: '/error/',
    name: 'Error',
    component: Error,
    meta: { title: `Error | ${commonTitle}` },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// 1つ前のルート
let fromRoute: Route | null

export const routerState = {
  // 履歴があるかどうかを返します
  get hasHistory(): boolean {
    return fromRoute ? true : false
  },
}

router.beforeEach(async (to, from, next) => {
  fromRoute = from && from.name ? from : null

  // 無視するクエリの削除処理
  const ignoreQueryNames = ['logged_in', 'signed_up'] // 無視するクエリ名リスト
  let queryRemoved = false
  const tmpQuery = Object.assign({}, to.query)
  ignoreQueryNames.forEach(element => {
    if (to.query && tmpQuery[element]) {
      delete tmpQuery[element]
      queryRemoved = true
    }
  })
  if (queryRemoved) {
    const newRoute = {
      query: tmpQuery,
    }
    router.replace(newRoute)
    next()
    return
  }

  // PageNoを更新
  if (to.path && to.path.indexOf('/page/') != -1) {
    searchStore.setPageNo(parseInt(to.params.pageno, 10))
  } else if (to.name && to.name.indexOf('Home') != -1) {
    searchStore.setPageNo(1)
  }

  // 詳細データの読み込み
  if (to && to.name && to.name.indexOf('Detail') != -1) {
    await ladyStore.loadLady(to.params.id)
  }

  // タイトルを更新
  if (to && to.meta && to.meta.title) {
    document.title = to.meta.title
  } else if (to && to.name && to.name.indexOf('Detail') != -1) {
    // 詳細ページ
    const lady = ladyStore.getLady
    if (sampleStore.getIsSample) {
      document.title = `${lady?.name} (SAMPLE) | ${commonTitle}`
    } else {
      document.title = `${lady?.name} | ${commonTitle}`
    }
  }

  if (sampleStore.getIsSample && to.path.indexOf('/sample') == -1) {
    //
    // Sampleページへリダイレクト
    //
    const newRoute = {
      path: '/sample' + to.path,
      query: to.query,
    }
    router.push(newRoute)
  }

  next()
})

export default router
