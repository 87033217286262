































import { defineComponent, reactive, onMounted, onUpdated, onUnmounted, PropType, toRefs, computed } from '@vue/composition-api'
import StringUtil from '@/utils/core/util/StringUtil'

// const
import { rankTypeList } from '@/const/search'

// type
import { LadyItemData } from '@/types/type'

export default defineComponent({
  components: {},

  props: {
    model: {
      type: Object as PropType<LadyItemData>,
    },
  },

  setup(props) {
    const state = reactive({
      // ランク
      rank: computed(() => {
        const data = rankTypeList.find(x => x.value == '' + props.model?.rank)
        return data?.text
      }),
      // No
      numberText: computed(() => {
        let r = ''
        if (props.model) {
          r = StringUtil.zeroPadding(parseInt(props.model.number, 10), 4)
        }
        return r
      }),
    })

    onMounted(() => {
      // do nothing.
    })

    onUpdated(() => {
      // do nothing.
    })

    onUnmounted(() => {
      // do nothing.
    })

    return {
      ...toRefs(state),
    }
  },
})
