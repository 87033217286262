import BaseView from '@/utils/core/view/BaseView'

class BaseController extends BaseView {
  /**
   * コンストラクタ
   *
   */
  constructor() {
    super()
  }
}

export default BaseController
