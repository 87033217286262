















































































































































import { routerState } from '@/router'
import { defineComponent, reactive, computed, onMounted, onUpdated, onUnmounted, toRefs } from '@vue/composition-api'
import { Carousel, Slide } from 'vue-carousel'
import StringUtil from '@/utils/core/util/StringUtil'

// const
import { rankTypeList } from '@/const/search'

// store
import { ladyStore } from '@/store/lady/lady'

// vue
import Modal from '@/components/templates/Modal.vue'
import ContactButton from '@/components/organisms/ContactButton.vue'

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Modal,
    ContactButton,
  },
  setup(prop, context) {
    const route = context.root.$route

    const state = reactive({
      id: null,
      carouselPageIndex: 0,
      lady: computed(() => {
        return ladyStore.getLady
      }),
      // ランク
      rank: computed(() => {
        const data = rankTypeList.find(x => x.value == '' + ladyStore.getLady?.rank)
        return data?.text
      }),
      // No
      numberText: computed(() => {
        let r = ''
        if (ladyStore.getLady) {
          r = StringUtil.zeroPadding(parseInt(ladyStore.getLady.number, 10), 4)
        }
        return r
      }),
    })

    onMounted(() => {
      // do nothing.
    })

    onUpdated(() => {
      // do nothing.
    })

    onUnmounted(() => {
      // do nothing.
    })

    // 画像のクリックハンドラ
    const clickImage = (index: number) => {
      state.carouselPageIndex = index
    }

    // 閉じる
    const close = () => {
      if (routerState.hasHistory) {
        context.root.$router.go(-1)
      } else {
        context.root.$router.push('/')
      }
    }

    return {
      ...toRefs(state),
      clickImage,
      close,
    }
  },
})
