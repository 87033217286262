



















import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  props: {
    image: {
      type: String,
    },
    imageStyle: {
      type: String,
    },
    ratio: {
      type: Number,
      default: 1,
    },
  },

  setup(props) {
    const state = reactive({
      // 画像表示要素のスタイル
      imageClass: '',
      retio: props.ratio * 100 + '%',
    })

    // 画像用見込み完了ハンドラ
    const onLoadImage = () => {
      state.imageClass = 'is-show'
    }

    return {
      state,
      onLoadImage,
    }
  },
})
