







import Timer from '@/utils/core/util/Timer'
import InviewController from '@/utils/core/controller/InviewController'
import { defineComponent, reactive, onMounted, onUpdated, onUnmounted, ref } from '@vue/composition-api'

export default defineComponent({
  components: {},

  props: {},

  setup() {
    const root = ref<HTMLElement>()

    const state = reactive({})

    // 要素が表示エリアに入ったらclassを当てる処理
    let inviewController
    const watchInView = (className: string) => {
      inviewController = new InviewController()
      inviewController.scale = 0.2
      inviewController.on('onEnter', (elem: HTMLElement) => {
        elem.classList.add('isInview')
        // 中にあるInView要素も反応させる
        if (elem.getAttribute('data-inview-noinnerinview') != 'true') {
          const tmpElms = elem.getElementsByClassName('innerInView')
          ;[].slice.call(tmpElms).forEach((elem: HTMLElement) => {
            elem.classList.add('isInview')
          })
        }
      })
      const list: HTMLCollection | undefined = root.value?.getElementsByClassName(className)
      if (list && list.length) {
        Array.prototype.slice.call(list).forEach(elem => {
          inviewController.add(elem)
        })
        inviewController.init()
      }
    }

    onMounted(async () => {
      // inView処理（１）inViewH
      const inViewHList: HTMLCollection = document.getElementsByClassName('inViewH')
      if (inViewHList.length) {
        watchInView('inViewH')
      }

      // inView処理（２）inView
      await Timer.wait(400 + inViewHList.length ? 1000 : 0)
      watchInView('inView')
    })

    onUpdated(() => {
      // do nothing.
    })

    onUnmounted(() => {
      if (inviewController) inviewController.dispose()
    })

    return {
      root,
      state,
    }
  },
})
