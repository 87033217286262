import store from '@/store'
import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'
// import Timer from '@/utils/core/util/Timer'
import ApiController, { LadyResponsePromise } from '@/api/ApiController'
import { LadyItemData } from '@/types/type'
import { ladiesStore } from '@/store/ladies/ladies'

@Module({ dynamic: true, store, name: 'lady', namespaced: true })
class LadyModule extends VuexModule {
  // 表示用の女性データ
  public lady: LadyItemData | null = null

  // 現在実行中の getLady
  public runningLadyResponsePromise: LadyResponsePromise | null = null

  /**
   * 表示用の女性データを返します
   */
  public get getLady() {
    return this.lady
  }

  /**
   * 表示用の女性データを更新
   * @param ladies 女性情報リスト
   */
  @Mutation
  public setLady(lady: LadyItemData) {
    this.lady = lady
  }

  /**
   * データを消去
   */
  @Mutation
  public clearLady() {
    this.lady = null
  }

  /**
   * 実行中のAPI処理Promise
   * @param promise
   */
  @Mutation
  public setRunningLadyPromise(promise: LadyResponsePromise | null) {
    this.runningLadyResponsePromise = promise
  }

  /**
   * 表示用の女性データをを取得します
   * @param id 女性ID
   */
  @Action
  public async loadLady(id: string) {
    // データを消去
    this.clearLady()

    const lady = ladiesStore.getLadies?.find(element => element.id == id)
    if (lady) {
      this.setLady(lady)
    } else {
      // API実行中の場合キャンセル
      if (this.runningLadyResponsePromise) {
        this.runningLadyResponsePromise.cancel()
        this.setRunningLadyPromise(null)
      }
      const runningLadyResponsePromise = new ApiController().getLady(id)
      this.setRunningLadyPromise(runningLadyResponsePromise)

      const lady: LadyItemData | void = await runningLadyResponsePromise.catch(() => {
        // エラー時 - ApiControllerの方で処理
      })
      if (lady) this.setLady(lady)
    }
  }
}

export const ladyStore = getModule(LadyModule)
