import BaseController from '@/utils/core/controller/BaseController'

/**
 * 横スクロール時に連携移動させる処理
 */
class HorizontalScrollSyncControler extends BaseController {
  // 要素リスト
  private views: Array<HTMLElement> = []

  // 位置
  private left = 0

  /**
   * コンストラクタ
   *
   */
  constructor() {
    super()
  }

  /**
   * イベントリスニング
   *
   */
  public startListen(): void {
    this.listenResize()
    this.listenScroll()
  }
  public stopListen(): void {
    this.unListenResize()
    this.unListenScroll()
  }

  /**
   * 要素の追加
   *
   */
  public add(view: HTMLElement): void {
    if (this.views.indexOf(view) == -1) {
      this.views.push(view)

      if (this.views.length == 1) {
        this.startListen()
      }
    }
  }

  /**
   * 要素の削除
   *
   */
  public remove(view: HTMLElement): void {
    const index = this.views.indexOf(view)
    if (index != -1) {
      view.style['left'] = ''
      this.views.splice(index, 1)
      if (this.views.length == 0) {
        this.stopListen()
      }
    }
  }

  /**
   * ハンドラ
   *
   */
  public onResize(): void {
    this.updateViews()
  }
  public onScroll(scrollY: number, scrollX: number): void {
    this.updateViews()
  }

  /**
   * 横位置の調整
   *
   */
  private updateViews(): void {
    const tmpLeft = this.getScrollPositionX()
    if (this.left == tmpLeft) {
      return
    } else {
      this.left = tmpLeft
    }

    this.views.forEach(element => {
      element.style['left'] = this.left * -1 + 'px'
    })
  }
}

export default HorizontalScrollSyncControler
