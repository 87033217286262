






import { defineComponent, reactive, onMounted, onUpdated, onUnmounted } from '@vue/composition-api'

// const
import { getErrorData, ERROR_UNKNOWN } from '@/const/error'

// vue
import InViewTransition from '@/components/templates/InViewTransition.vue'
import Error from '@/components/templates/Error.vue'

export default defineComponent({
  props: {
    // エラーID
    errorId: {
      type: String,
    },
  },

  components: {
    InViewTransition,
    Error,
  },
  setup(props, context) {
    // props設定から確認
    let errorData = getErrorData(props.errorId)

    // エラーがない場合、query を確認
    if (errorData.id == ERROR_UNKNOWN && context.root.$route.query.id) {
      const id = encodeURI(context.root.$route.query.id as string)
      errorData = getErrorData(id)
    }

    const state = reactive({
      errorName: errorData?.name,
      errorTitle: errorData?.title,
      errorText: errorData?.text,
    })

    onMounted(() => {
      // do nothing.
    })

    onUpdated(() => {
      // do nothing.
    })

    onUnmounted(() => {
      // do nothing.
    })

    return {
      state,
    }
  },
})
