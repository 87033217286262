class StringUtil {
  /**
   * 桁を揃える (もとの桁のほうが大きい場合は、桁を増やす)
   * @returns {string}
   */
  public static zeroPadding(num: number, len: number): string {
    const tmp = Array(len).join('0') + num
    len = len < String(num).length ? String(num).length : len
    return tmp.slice(-len)
  }
}

export default StringUtil
