export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const ME = 'ME'
export const LADIES = 'LADIES'
export const LADY = 'LADY'

class ApiPath {
  private static _apiBasePath = ''

  private static _apiType = ''

  /**
   * APIのパスを返します
   */
  public static getPath(pathName: string, param1: string | null = null): string {
    let path = ''

    const apiType = ApiPath._apiType != '' ? '/' + ApiPath._apiType : ''

    switch (pathName) {
      case LOGIN:
        path = ApiPath._apiBasePath + '/user/login'
        break
      case LOGOUT:
        path = ApiPath._apiBasePath + '/user/logout'
        break
      case ME:
        path = ApiPath._apiBasePath + apiType + '/user/api/v1/me'
        break
      case LADIES:
        path = ApiPath._apiBasePath + apiType + '/user/api/v1/ladies/'
        break
      case LADY:
        path = ApiPath._apiBasePath + apiType + '/user/api/v1/ladies/' + param1
        break

      default:
        break
    }
    return path
  }

  /**
   * APIの基本パスを指定します
   */
  public static set apiBasePath(pathName: string) {
    ApiPath._apiBasePath = pathName
  }

  /**
   * APIの種類を指定します
   */
  public static set apiType(apiType: string) {
    ApiPath._apiType = apiType
  }
}

export default ApiPath
