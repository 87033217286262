import store from '@/store'
import { Module, VuexModule, getModule, Mutation /* Action */ } from 'vuex-module-decorators'

@Module({ dynamic: true, store, name: 'sample', namespaced: true })
class SampleModule extends VuexModule {
  // サンプル表示かどうか
  public isSample = false

  /**
   * サンプル表示かどうかを返します
   */
  public get getIsSample() {
    return this.isSample
  }

  /**
   * サンプル表示かどうかを設定します
   * @param value サンプル表示かどうか
   */
  @Mutation
  public setIsSample(value: boolean) {
    this.isSample = value
  }
}

export const sampleStore = getModule(SampleModule)
