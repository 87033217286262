import { ErrorData } from '@/types/type'

// エラーID
export const ERROR_UNKNOWN = 'unknown'
export const ERROR_TIMEOUT = 'timeout'
export const ERROR_400 = '400'
export const ERROR_401 = '401'
export const ERROR_402 = '402'
export const ERROR_403 = '403'
export const ERROR_404 = '404'
export const ERROR_405 = '405'
export const ERROR_406 = '406'
export const ERROR_407 = '407'
export const ERROR_408 = '408'
export const ERROR_409 = '409'
export const ERROR_410 = '410'
export const ERROR_411 = '411'
export const ERROR_412 = '412'
export const ERROR_413 = '413'
export const ERROR_414 = '414'
export const ERROR_415 = '415'
export const ERROR_416 = '416'
export const ERROR_417 = '417'
export const ERROR_421 = '421'
export const ERROR_422 = '422'
export const ERROR_423 = '423'
export const ERROR_424 = '424'
export const ERROR_425 = '425'
export const ERROR_426 = '426'
export const ERROR_428 = '428'
export const ERROR_429 = '429'
export const ERROR_431 = '431'
export const ERROR_451 = '451'
export const ERROR_500 = '500'
export const ERROR_501 = '501'
export const ERROR_502 = '502'
export const ERROR_503 = '503'
export const ERROR_504 = '504'
export const ERROR_505 = '505'
export const ERROR_506 = '506'
export const ERROR_507 = '507'
export const ERROR_508 = '508'
export const ERROR_510 = '510'
export const ERROR_511 = '511'

const defaultErrorData: ErrorData = {
  id: '',
  name: '',
  title: 'Error',
  text: 'エラーが発生しました。',
}

// エラー
export const errorList: Array<ErrorData> = [
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_400,
    name: '400 Bad Request',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_401,
    name: '401 Unauthorized',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_402,
    name: '402 Payment Required',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_403,
    name: '403 Forbidden',
  }),
  {
    id: ERROR_404,
    name: '404 Not Found',
    title: 'Sorry…<br>We couldn’t find that page',
    text: 'お探しのページは見つかりませんでしたが、<br>選ばれた紳士／淑女が<br>あなたをお待ちしています。',
  },
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_405,
    name: '405 Method Not Allowed',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_406,
    name: '406 Not Acceptable',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_407,
    name: '407 Proxy Authentication Required',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_408,
    name: '408 Request Timeout',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_409,
    name: '409 Conflict',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_410,
    name: '410 Gone',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_411,
    name: '411 Length Required',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_412,
    name: '412 Precondition Failed',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_413,
    name: '413 Payload Too Large',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_414,
    name: '414 URI Too Long',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_415,
    name: '415 Unsupported Media Type',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_416,
    name: '416 Range Not Satisfiable',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_417,
    name: '417 Expectation Failed',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_421,
    name: '421 Misdirected Request',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_422,
    name: '422 Unprocessable Entity',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_423,
    name: '423 Locked',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_424,
    name: '424 Failed Dependency',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_425,
    name: '425 Too Early',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_426,
    name: '426 Upgrade Required',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_428,
    name: '428 Precondition Required',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_429,
    name: '429 Too Many Requests',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_431,
    name: '431 Request Header Fields Too Large',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_451,
    name: '451 Unavailable For Legal Reasons',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_500,
    name: '500 Internal Server Error',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_501,
    name: '501 Not Implemented',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_502,
    name: '502 Bad Gateway',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_503,
    name: '503 Service Unavailable',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_504,
    name: '504 Gateway Timeout',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_505,
    name: '505 HTTP Version Not Supported',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_506,
    name: '506 Variant Also Negotiates',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_507,
    name: '507 Insufficient Storage (WebDAV)',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_508,
    name: '508 Loop Detected (WebDAV)',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_510,
    name: '510 Not Extended',
  }),
  Object.assign(Object.assign({}, defaultErrorData), {
    id: ERROR_511,
    name: '511 Network Authentication Required',
  }),
]

/**
 * エラーIDからエラー情報を返します
 */
export const getErrorData = (errorId: string | undefined): ErrorData => {
  let errorData = errorList.find(x => x.id == errorId)

  if (!errorData) {
    errorData = {
      id: ERROR_UNKNOWN,
      name: 'Unknown Error',
      title: 'Error',
      text: '不明なエラーが発生しました。' + (errorId ? ` (${errorId})` : ''),
    }
  }
  return errorData
}
