import { OptionData } from '@/types/type'

// 検索条件の型 SearchParameterのプロパティを列挙したもの
export const SearchParameterNames = ['order', 'rank', 'age', 'height', 'bust', 'occupation', 'q']

// 並び順
export const orderRecommended = 'recommended'

// ランク
export const rankTypeList: Array<OptionData> = [
  {
    id: '1',
    text: '★★★',
    value: '3',
  },
  {
    id: '2',
    text: '★★',
    value: '2',
  },
  {
    id: '3',
    text: '★',
    value: '1',
  },
]

// 年齢
export const ageTypeList: Array<OptionData> = [
  {
    id: '1',
    text: '20歳未満',
    value: '1',
  },
  {
    id: '2',
    text: '20〜24歳',
    value: '2',
  },
  {
    id: '3',
    text: '25〜29歳',
    value: '3',
  },
  {
    id: '4',
    text: '30歳以上',
    value: '4',
  },
]

// 身長
export const heightTypeList: Array<OptionData> = [
  {
    id: '1',
    text: '160cm未満',
    value: '1',
  },
  {
    id: '2',
    text: '160cm以上',
    value: '2',
  },
]

// バスト
export const bustTypeList: Array<OptionData> = [
  {
    id: '1',
    text: 'A〜C',
    value: '1',
  },
  {
    id: '2',
    text: 'D〜F',
    value: '2',
  },
  {
    id: '3',
    text: 'G以上',
    value: '3',
  },
]

// 職業
export const occupationTypeList: Array<OptionData> = [
  {
    id: '1',
    text: '会社員',
    value: 'employee',
  },
  {
    id: '2',
    text: 'モデル・芸能',
    value: 'model',
  },
  {
    id: '3',
    text: 'CA・受付',
    value: 'ca',
  },
  {
    id: '4',
    text: '経営者',
    value: 'owner',
  },
  {
    id: '5',
    text: '接客業',
    value: 'service',
  },
  {
    id: '6',
    text: '学生',
    value: 'student',
  },
  {
    id: '7',
    text: '主婦',
    value: 'housewife',
  },
  {
    id: '8',
    text: 'その他',
    value: 'other',
  },
]
