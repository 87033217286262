















import { defineComponent, reactive, onMounted, onUpdated, onUnmounted, toRefs, ref } from '@vue/composition-api'
import HorizontalScrollSyncControler from '@/utils/core/controller/HorizontalScrollSyncControler'

// vue
import SimpleHeader from '@/components/organisms/SimpleHeader.vue'

export default defineComponent({
  components: {
    SimpleHeader,
  },

  props: {
    // 背景色
    bgColorClass: {
      type: String,
      default: undefined,
    },
  },

  setup(prop, context) {
    const root = ref<HTMLElement>()

    const state = reactive({})

    let horizontalScrollSyncControler

    onMounted(() => {
      if (root.value) {
        horizontalScrollSyncControler = new HorizontalScrollSyncControler()
        horizontalScrollSyncControler.add(root.value)
        horizontalScrollSyncControler.startListen()
      }
    })

    onUpdated(() => {
      // do nothing.
    })

    onUnmounted(() => {
      if (horizontalScrollSyncControler) {
        horizontalScrollSyncControler.stopListen()
        horizontalScrollSyncControler = null
      }
    })

    // 閉じる
    const close = () => {
      context.emit('close')
    }

    return {
      ...toRefs(state),
      root,
      close,
    }
  },
})
