











import { defineComponent, PropType, reactive, toRefs, ref, onMounted, onUnmounted } from '@vue/composition-api'
import { OptionData } from '@/types/type'

export default defineComponent({
  model: {
    prop: 'value',
    event: 'change-value',
  },

  props: {
    defaultText: {
      type: String,
      default: '',
    },
    list: {
      type: Array as PropType<Array<OptionData>>,
    },
    value: {
      type: String,
      default: null,
    },
  },

  setup(props, context) {
    // フォーム要素
    const formSelectRef = ref<HTMLFormElement>()

    const state = reactive({
      // 選択中のリストインデックス
      selectedIndex: 0,
    })

    // value
    const localValue = props.value == undefined ? '' : props.value

    onMounted(() => {
      // 選択中のリストインデックスを反映
      state.selectedIndex = formSelectRef.value?.selectedIndex
    })

    onUnmounted(() => {
      // do nothing.
    })

    // selectフォームの変化ハンドラ
    const changeSelect = () => {
      const selectedIndex = formSelectRef.value?.selectedIndex as number
      let newValue
      if (selectedIndex != undefined && selectedIndex != 0) {
        state.selectedIndex = selectedIndex
        newValue = props.list?.[selectedIndex - 1].value
      }
      state.selectedIndex = selectedIndex
      context.emit('change-value', newValue)
    }

    return {
      ...toRefs(state),
      localValue,
      formSelectRef,
      changeSelect,
    }
  },
})
