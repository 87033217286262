import axios from 'axios'

import { LadyItemData, LadiesResponse, SearchParameter } from '@/types/type'
import ApiPath, { ME, LADIES, LADY } from '@/api/ApiPath'
import router from '@/router'

// const
import { ERROR_UNKNOWN, ERROR_TIMEOUT } from '@/const/error'

// store
import { sampleStore } from '@/store/sample/sample'

// Cookieを送信
axios.defaults.withCredentials = true

// キャンセルできるPromiseの定義
interface CancellablePromise<T> extends Promise<T> {
  cancel: () => void
}
export type LadiesResponsePromise = CancellablePromise<LadiesResponse>
export type LadyResponsePromise = CancellablePromise<LadyItemData>

class ApiController {
  // タイムアウト
  private timeout = 30000 // 30秒

  /**
   * コンストラクタ
   *
   */
  constructor() {
    // do nothing.
  }

  /**
   * ME ログイン状態を確認します。
   */
  public async getMe(): Promise<void> {
    const apiType = ME
    return new Promise((resolve, reject) => {
      const params = Object.assign(this.getParams(), {
        params: {},
      })
      axios
        .get(ApiPath.getPath(apiType), params)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(response => {
          // 正常
          resolve()
        })
        .catch(async error => {
          reject(error)
        })
    })
  }

  /**
   * LADIES Ladiesリストを取得します。
   */
  public getLadies(searchParameter: SearchParameter | null, pageNo = 0, limit: number | null = null): LadiesResponsePromise {
    const apiType = LADIES

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    searchParameter = searchParameter == null ? {} : searchParameter
    const p: Partial<LadiesResponsePromise> = new Promise<LadiesResponse>((resolve, reject) => {
      const params = Object.assign(this.getParams(), {
        cancelToken: source.token,
        params: Object.assign({ skip: pageNo }, searchParameter),
        limit: limit,
      })

      axios
        .get(ApiPath.getPath(apiType), params)
        .then(response => {
          resolve({
            totalCount: response.headers['x-total-count'] | response.headers['X-Total-Count'],
            ladies: response.data as Array<LadyItemData>,
          })
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            // キャンセル済み
            return
          } else {
            reject(error)
            this.handleError(error)
          }
        })
    })

    // キャンセル
    p.cancel = () => {
      if (source) {
        source.cancel()
      }
    }

    return p as LadiesResponsePromise
  }

  /**
   * LADY 単体Ladyを取得します。
   */
  public getLady(id: string): LadyResponsePromise {
    const apiType = LADY

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    const p: Partial<LadyResponsePromise> = new Promise<LadyItemData>((resolve, reject) => {
      const params = Object.assign(this.getParams(), {
        cancelToken: source.token,
        lady_id: id,
      })

      axios
        .get(ApiPath.getPath(apiType, id), params)
        .then(response => {
          resolve(response.data as LadyItemData)
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            // キャンセル済み
            return
          } else {
            reject(error)
            this.handleError(error)
          }
        })
    })

    // キャンセル
    p.cancel = () => {
      if (source) {
        source.cancel()
      }
    }

    return p as LadyResponsePromise
  }

  /**
   * 送信で共通に必要なパラメータ情報を返します
   */
  public getParams() {
    return {
      params: {},
      timeout: this.timeout,
      headers: {},
    }
  }

  /**
   * エラーからエラーコードを返します
   */
  private getErrorCode(error): string {
    if (error.code && error.code == 'ECONNABORTED') {
      // Timeoutが発生
      return ERROR_TIMEOUT
    } else if (error.response && error.response.status) {
      return String(error.response.status)
    } else if (!error.response) {
      // レスポンスがない
      return ERROR_UNKNOWN
    } else {
      // 不明なエラー
      return ERROR_UNKNOWN
    }
  }

  /**
   * 共通エラーハンドラ
   */
  private handleError(error: Error): void {
    const errorCode = this.getErrorCode(error)
    if (errorCode == '401') {
      // 未ログイン時：Websiteでエラーを表示
      if (sampleStore.getIsSample) {
        location.replace(process.env.VUE_APP_WEBSITE + '/?sample_failed=2')
      } else {
        location.replace(process.env.VUE_APP_WEBSITE + '/?login_failed=2')
      }
    } else {
      router.push({ path: '/error', query: { id: errorCode } })
    }
  }
}

export default ApiController
