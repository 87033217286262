



























import HorizontalScrollSyncControler from '@/utils/core/controller/HorizontalScrollSyncControler'

import { defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api'
import Header from '@/components/organisms/Header.vue'
import Footer from '@/components/organisms/Footer.vue'

// store
import { searchStore } from '@/store/search/search'
import { ladiesStore } from '@/store/ladies/ladies'

export default defineComponent({
  components: {
    Header,
    Footer,
  },

  props: {
    dataPageGroup: {
      type: String,
      default: 'error',
    },
  },
  setup(props, context) {
    const background = ref<HTMLElement>()

    let horizontalScrollSyncControler

    // ホームに移動
    const clickHome = () => {
      // リセット
      searchStore.updateSearchParameter({})
      context.root.$router.push({ path: '/', query: {} })

      // 女性リストを読み込み
      ladiesStore.loadLadies()
    }

    onMounted(() => {
      if (background.value) {
        horizontalScrollSyncControler = new HorizontalScrollSyncControler()
        horizontalScrollSyncControler.add(background.value)
        horizontalScrollSyncControler.startListen()
      }
    })

    onUnmounted(() => {
      if (horizontalScrollSyncControler) {
        horizontalScrollSyncControler.stopListen()
        horizontalScrollSyncControler = null
      }
    })

    return { background, clickHome }
  },
})
