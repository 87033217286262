import * as _ from 'lodash'
import store from '@/store'
import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'
import { SearchParameter } from '@/types/type'

// const
import { SearchParameterNames } from '@/const/search'

@Module({ dynamic: true, store, name: 'search', namespaced: true })
class SearchModule extends VuexModule {
  // 検索条件
  public searchParameter: SearchParameter | null = null

  // ページ番号（skip）
  public pageNo = 1

  // ページの最大番号
  public pageMaxNo: number | null = null

  // 1ページの上限件数
  public limit = 10

  /**
   * 現在の検索条件を返します
   */
  public get getSearchParameter(): SearchParameter | null {
    return this.searchParameter
  }

  /**
   * ページ番号を返します
   */
  public get getPageNo(): number {
    return this.pageNo
  }

  /**
   * skip値（ページ番号−１を返します）
   */
  public get getSkip(): number {
    return (this.pageNo - 1) * this.limit
  }

  /**
   * ページの最大番号を返します
   */
  public get getPageMaxNo(): number {
    return this.pageMaxNo == null ? 0 : this.pageMaxNo
  }

  /**
   * 1ページの上限件数を返します
   */
  public get getLimit(): number {
    return this.limit
  }

  /**
   * ソート条件を返します
   */
  public get getOrder(): string | undefined {
    return this.searchParameter?.order
  }

  /**
   * 検索条件を更新
   * @param searchParameter 新たな検索条件
   */
  @Mutation
  public setSearchParameter(searchParameter: SearchParameter) {
    // 上書き(許可するパラメータのみに整理したものを反映)
    this.searchParameter = _.pick(searchParameter, SearchParameterNames)
  }

  /**
   * ページ番号を更新
   * @param value ページ番号
   */
  @Mutation
  public setPageNo(value: number) {
    this.pageNo = value
  }

  /**
   * ページの最大番号を更新
   * @param value 最大ページ番号
   */
  @Mutation
  public setPageMaxNo(value: number) {
    this.pageMaxNo = value
  }

  /**
   * １ページの上限件数を更新
   * @param value 上限件数
   */
  @Mutation
  public setLimit(value: number) {
    this.limit = value
  }

  /**
   * 検索条件をセット
   * @param searchParameter 検索条件
   * @param marge 条件を既存条件に統合するかどうか
   */
  @Action
  public updateSearchParameter(searchParameter: SearchParameter, marge = false) {
    if (marge && this.searchParameter) {
      const newSearchParameter = Object.assign({}, this.searchParameter)
      for (const item in this.searchParameter) {
        newSearchParameter[item] = searchParameter[item]
      }
      this.setSearchParameter(newSearchParameter)
    } else {
      this.setSearchParameter(searchParameter)
    }
  }

  /**
   * オーダー条件を変更します
   * @param value オーダー条件種別
   */
  @Action
  public updateOrder(value: string | undefined) {
    if (this.searchParameter) {
      const newSearchParameter = Object.assign({}, this.searchParameter)
      newSearchParameter.order = value
      this.setSearchParameter(newSearchParameter)
    } else {
      this.setSearchParameter({
        order: value,
      })
    }
  }

  /**
   * 検索条件をセット
   */
  @Action
  public updateSearchParameterFromQuery(query: Record<string, string>) {
    this.setSearchParameter(query)
  }
}

export const searchStore = getModule(SearchModule)
