





import { defineComponent, reactive, onMounted } from '@vue/composition-api'
import FadeRatioImage from '@/plugins/materials/atoms/FadeRatioImage.vue'

export default defineComponent({
  props: {
    image: {
      type: String,
    },
    ratio: {
      type: Number,
      default: 1,
    },
  },

  components: {
    FadeRatioImage,
  },

  setup(prop) {
    //
    const getRandomColor = () => {
      const letters = '0123456789ABCDEF'
      let color = ''
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    }

    const state = reactive({
      image: prop.image + getRandomColor() + '/' + getRandomColor(),
    })

    onMounted(() => {
      // do nothing.
    })

    return {
      state,
    }
  },
})
