



















import { defineComponent, reactive, toRefs, computed } from '@vue/composition-api'

export default defineComponent({
  model: {
    prop: 'value',
    event: 'change-value',
  },
  props: {
    startNumber: {
      type: Number,
      required: true,
    },
    endNumber: {
      type: Number,
      required: true,
    },
    currentNumber: {
      type: Number,
      required: true,
    },
  },

  setup(props, context) {
    const state = reactive({
      // ページ番号リスト
      pageNoList: computed(() => {
        const r: number[] = []
        for (let i = props.startNumber; i <= props.endNumber; i++) {
          r.push(i)
        }
        return r
      }),

      // 戻るボタンのページ番号
      prevNumber: computed(() => {
        return props.currentNumber - 1
      }),

      // 次へボタンのページ番号
      nextNumber: computed(() => {
        let r = props.currentNumber + 1
        r = r >= props.endNumber ? props.endNumber : r
        return r
      }),
    })

    const click = index => {
      context.emit('click', index)
    }

    return {
      ...toRefs(state),
      click,
    }
  },
})
