












import { defineComponent } from '@vue/composition-api'
import ApiPath, { LOGOUT } from '@/api/ApiPath'

// store
import { sampleStore } from '@/store/sample/sample'
import { searchStore } from '@/store/search/search'
import { ladiesStore } from '@/store/ladies/ladies'

export default defineComponent({
  components: {},
  setup(props, context) {
    // サンプルページかどうか
    const isSample = sampleStore.getIsSample

    // サンプルページかどうか
    const logoutURL = ApiPath.getPath(LOGOUT)

    // ホームに移動
    const clickHome = () => {
      // リセット
      searchStore.updateSearchParameter({})
      context.root.$router.push({ path: '/', query: {} })

      // 女性リストを読み込み
      ladiesStore.loadLadies()
    }

    return { isSample, logoutURL, clickHome }
  },
})
