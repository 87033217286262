














import { defineComponent, onMounted, onUpdated, onUnmounted } from '@vue/composition-api'
import ApiPath, { LOGIN } from '@/api/ApiPath'

// store
import { sampleStore } from '@/store/sample/sample'

export default defineComponent({
  // components: {},

  // props: {},

  setup() {
    // const state = reactive({})

    // ログイン用URL
    const loginUrl = ApiPath.getPath(LOGIN)

    onMounted(() => {
      // do nothing.
    })

    onUpdated(() => {
      // do nothing.
    })

    onUnmounted(() => {
      // do nothing.
    })

    return {
      loginUrl,
      sampleStore,
    }
  },
})
