
























import { defineComponent, reactive, onMounted, onUpdated, onUnmounted } from '@vue/composition-api'

export default defineComponent({
  components: {},
  setup() {
    const state = reactive({
      // メールアドレス
      mailAddress: 'info@vingt-quatre-24.com',
      // ウェブサイトのURL
      website: process.env.VUE_APP_WEBSITE,
    })

    onMounted(() => {
      // do nothing.
    })

    onUpdated(() => {
      // do nothing.
    })

    onUnmounted(() => {
      // do nothing.
    })

    return {
      state,
    }
  },
})
