












































import { defineComponent, reactive, onMounted, onUpdated, onUnmounted, toRefs } from '@vue/composition-api'

// const
import { rankTypeList, ageTypeList, heightTypeList, bustTypeList, occupationTypeList } from '@/const/search'

// store
import { searchStore } from '@/store/search/search'
import { ladiesStore } from '@/store/ladies/ladies'

// vue
import Modal from '@/components/templates/Modal.vue'

export default defineComponent({
  components: {
    Modal,
  },
  setup(prop, context) {
    const state = reactive({
      id: null,
      // 検索条件（検索が実行されるまでは検索条件を保持しないようにコピーを作成し保持）
      searchParameter: Object.assign({}, searchStore.getSearchParameter),
    })

    onMounted(() => {
      // do nothing.
    })

    onUpdated(() => {
      // do nothing.
    })

    onUnmounted(() => {
      // do nothing.
    })

    // プルダウンの指定ないときのテキスト
    const pullDownDefaultText = '指定なし'

    // 検索を実行
    const submit = () => {
      // 検索条件を保持
      searchStore.updateSearchParameter(state.searchParameter)

      // 女性リストを更新
      const searchParam = Object.assign({}, searchStore.getSearchParameter) as Record<string, never>
      if (searchParam) {
        context.root.$router.push({ path: '/', query: searchParam })
        // 女性リストを読み込み
        ladiesStore.loadLadies()
      }
    }

    // 閉じる
    const close = () => {
      context.root.$router.go(-1)
    }

    return {
      ...toRefs(state),
      rankTypeList,
      ageTypeList,
      heightTypeList,
      bustTypeList,
      occupationTypeList,
      submit,
      close,
      pullDownDefaultText,
    }
  },
})
